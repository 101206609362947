.invoice h1,h2,h3,h4,h5,h6 {
    margin: 0;
    padding: 0;
}
.invoice p{
    margin: 0;
    padding: 0;
}
.invoice .container{
    width: 70%;
    /* margin-right: auto;
    margin-left: auto; */
}
.invoice .brand-section{
   background-color: #0d1033;
   padding: 10px 40px;
}
.invoice .logo{
    width: 50%;
}

.invoice .row{
    display: flex;
    flex-wrap: wrap;
}
.invoice .col-6{
    width: 50%;
    flex: 0 0 auto;
}
.invoice .text-white{
    color: #fff;
}
.invoice .company-details{
    float: right;
    text-align: right;
}
.invoice .body-section{
    padding: 16px;
    border: 1px solid gray;
    /* border-bottom: none; */
}
.invoice .heading{
    font-size: 20px;
    margin-bottom: 08px;
}
.invoice .sub-heading{
    color: #262626;
    margin-bottom: 05px;
}
.invoice table{
    background-color: #fff;
    width: 100%;
    border-collapse: collapse;
}
.invoice table thead tr{
    border: 1px solid #111;
    background-color: #f2f2f2;
}
.invoice table td {
    vertical-align: middle !important;
    text-align: center;
}
.invoice table th, table td {
    padding-top: 08px;
    padding-bottom: 08px;
}
.invoice .table-bordered{
    box-shadow: 0px 0px 5px 0.5px gray;
}
.invoice .table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6;
}
.invoice .text-right{
    text-align: end;
}
.invoice .w-20{
    width: 20%;
}
.invoice .float-right{
    float: right;
}