body {
  margin: 0 !important;
  font-size: 0.875rem !important;
}
.topsidelogo {
  object-fit: contain;
  object-position: left;
}

.containerpaper {
  margin-top: 64px;
}

.MuiGrid-root::-webkit-scrollbar {
  display: none;
}

.MuiTab-wrapper {
  flex-direction: row !important;
  align-items: initial !important;
}
.MuiBadge-badge {
  z-index: 0 !important;
}

.arabic .notificationList {
  right: initial !important;
  left: 90px;
}
.arabic input {
  unicode-bidi: plaintext;
  text-align: right;
}

.img-zoom-container {
  position: relative;
}
figure.zoom {
  /* background-position: 50% 50%;
  position: relative;
  margin: 150px auto;
  border: 5px solid white;
  box-shadow: -1px 5px 15px black;
  height: 300px;
  width: 500px;
  overflow: hidden; */
  cursor: zoom-in;
}

figure.zoom img:hover {
  opacity: 0;
}

figure.zoom img {
  transition: opacity 0.5s;
  display: block;
  width: 100%;
}
.img-zoom-lens {
  position: absolute;
  border: 1px solid #d4d4d4;
  /*set the size of the lens:*/
  width: 80px;
  height: 80px;
}

.img-zoom-result {
  border: 1px solid #d4d4d4;
  /*set the size of the result div:*/
  width: 100%;
  height: 100%;
  z-index: 100;
  position: absolute;
  left: 100%;
}

/* Mega Menu Css STart */
.carousel-container-ltr {
  direction: ltr;
}
.arabic .img-zoom-result {
  right: 100%;
  left: 0%;
}
.arabic .img-zoom-lens {
  visibility: hidden;
}
.arabic .float-right {
  float: left !important;
}
.arabic .back-button {
  left: 30px;
}

.arabic .invoiceAr {
  margin-right: auto;
  margin-left: initial !important;
}

/* RAJU css start 21/02/2022 */
.gray {
  background-color: #f3f5f4 !important;
}
.black-text {
  color: #333333 !important;
}
.black-bg {
  background-color: #333 !important;
}
.MuiButton-containedPrimary {
  background-color: #e1900f !important;
  border: 1px solid #e1900f !important;
}
.MuiGrid-root .MuiButton-containedPrimary:hover {
  background: transparent !important;
  border: 1px solid #e1900f;
  color: #e1900f !important;
}
.text-white {
  color: #fff !important;
}
.btn-opcity .MuiSvgIcon-root {
  fill: #000;
}
.footertext .MuiTypography-h4 {
  color: #333;
}
.footertext ul li a {
  color: #333;
}
.footertext ul li span {
  color: #333;
}
.bg-white {
  width: 100%;
  background: #fff;
}
.bg-white > div {
  background: #fff;
}
.w-100 {
  width: 100%;
}
.mt-2 {
  margin: 15px 0px 0px;
}
.topbarouter {
  background-color: #fff;
}
.sooqiraq-btn,
.allcatbtn button {
  background-color: #e1900f !important;
  border: 1px solid #e1900f !important;
}
.searchproduct .MuiBox-root {
  margin: 23px auto !important;
}
.selectlangugestab button {
  border: 0px;
  margin: 10px 0px 0px 15px;
}
.searchproduct .MuiAutocomplete-hasPopupIcon {
  max-width: 100% !important;
}
/* Home new section css start */
.BoxInner {
  background: #f3f5f4;
  padding: 20px;
}
.topbarouter ul li {
  padding: 0px 5px;
}
.topbarouter ul li button {
  margin: 0px 5px 0px 0px !important;
  color: #000;
  padding: 0px;
}

.BoxInner img {
  width: 100%;
}

.BoxInner h3 {
  color: #252525;
  font-weight: 600;
  margin-bottom: 18px;
  font-size: 18px !important;
}

.BoxInner a {
  text-decoration: none !important;
  display: block;
}

.productcat img {
  height: 100%;
}

.arabic .react-tel-input .selected-flag .arrow {
  right: 20px;
}

.Someinnerproduct {
  background: #fff;
  padding: 10px 10px 5px;
}

.productcat {
  padding: 8px;
  background: #f3f5f4;
}
.mainboxproduct {
  padding: 0px 8px;
}
.supports {
  width: 87%;
  text-align: center;
  color: #000;
  font-size: 15px !important;
  white-space: nowrap;
  font-weight: 600 !important;
}

.supports a {
  color: #262626;
  text-decoration: none !important;
  margin: 0px 5px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
}

.selectlanguage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectlanguage img {
  width: 21px;
  border-radius: 2px;
  height: 20px;
  object-fit: cover;
}
.selectlanguage div {
  border: 0px !important;
  box-shadow: none !important;
  padding-left: 2px;
  z-index: 10;
}
.selectlanguage .css-1s2u09g-control ,.selectlanguage .css-13cymwt-control {
  background: transparent !important;
}
.selectlanguage {
  border: 0;
  min-width: auto !important;
  padding-left: 5px;
  border-radius: 5px;
}
.selectlanguage .custom-select .css-2613qy-menu {
  background: #000 !important;
}

.selectlanguage {
  position: relative;
}

.selectlanguage::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  right: -1px;
}
.rightheader {
  text-align: right;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.css-1pahdxg-control {
  background: transparent !important;
}
.arabic .selectlanguage::after {
  right: initial;
  left: -1px;
}
.selectlanguage.languge {
  width: 100px;
}
.topbarouter ul li:last-child {
  padding-right: 12px;
}
.topbarouter .allcatbtn button {
  margin-left: 0;
}
#rfs-btn span {
  position: relative;
  left: 6px;
}
.arabic #rfs-btn span {
  position: relative;
  left: -6px;
}
/* .image-gallery-slides img {
  width: 100% !important;
} */
.image-gallery-icon.image-gallery-fullscreen-button,
.image-gallery-play-button {
  display: none;
}
.fixsizeitems {
  width: 100% !important;
  margin: 0px;
}

.fixsizeitems .css-0 {
  width: 100% !important;
}
.MuiGrid-spacing-xs-4 {
  margin: 0px !important;
}
.fixsizeitems .MuiPaper-root {
  width: 100% !important;
}
.fixsizeitems .css-0 .MuiPaper-root {
  width: auto !important;
}

@media(max-width: 1920px){
  .mywishlist-outer {
      padding: 0px 15px;
  }
}
@media (max-width: 1500px) {
  .supports {
    font-size: 13px !important;
    width: 100% !important;
  }
  .shareiconup .makeStyles-container-262 {
    width: 320px;
  }
  .shareiconup svg + div {
    width: 330px;
  }
}
@media (max-width: 1250px) {
  .searchproduct .MuiAutocomplete-hasPopupIcon {
    width: 450px !important;
  }
  .griditemlist {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 1199.98px) {
  .supports {
    display: none;
  }
  .BoxInner h3 {
    margin-bottom: 10px;
    min-height: 40px;
    font-size: 19px !important;
  }
  .BoxInner .productcat img {
    height: 100px;
  }
  .BoxInner img {
    height: 342px;
  }
}
@media (max-width: 991.98px) {
  .griditemlist {
    grid-template-columns: repeat(3, 1fr);
  }
  .BoxInner {
    padding: 10px;
  }
  .BoxInner h3 {
    font-size: 16px !important;
  }
  .BoxInner .productcat img {
    height: 180px;
  }
  .BoxInner img {
    height: 494px;
  }
  .supports {
    font-size: 14px !important;
  }
  img.logoimg {
    width: 50px;
  }
  .mobilehide {
    display: none;
  }
  .topsidelogo {
    object-fit: contain;
    object-position: left;
  }
  .togglebutton button {
    border: 1px solid #6da9d2;
  }

  .togglebutton button path {
    fill: #6da9d2;
  }
}
@media (max-width: 850px) {
  .BoxInner .productcat img {
    height: 120px;
  }
  .BoxInner img {
    height: 371px;
  }
}
@media (max-width: 767.98px) {
  .griditemlist {
    grid-template-columns: repeat(2, 1fr);
  }
  .BoxInner .productcat img {
    height: 92px;
  }
  .BoxInner img {
    height: 312px;
  }
  .productcat h5 {
    font-size: 14px !important;
  }
}
@media (max-width: 600px) {
  .BoxInner .productcat img {
    height: 132px;
    object-fit: contain;
  }
  .BoxInner h3 {
    min-height: initial;
  }
}
[dir="rtl"] .carousel-container {
  direction: ltr;
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 60px !important;
  width: 40px !important;
}
[dir="rtl"] .react-tel-input .form-control {
  padding-right: 48px;
}
/* [dir="rtl"] .react-tel-input {
  direction: ltr;
} */
[dir="rtl"] .react-tel-input .flag-dropdown {
  padding: 4px;
}
.orderProductImage {
  max-width: 100% !important;
}
.detailspagettab {
  display: block;
  padding: 22px 0px 0px;
}

.productshowdelivery {
  display: flex;
}

.productshowdelivery a {
  width: 45px;
  text-align: center;
  position: relative;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #69c0d4;
  margin: 3px 6px;
  border-radius: 55px;
  cursor: pointer;
  transition: 0.2s;
}

.productshowdelivery a span {
  position: absolute;
  top: -40px;
  background: #69c0d4;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  white-space: nowrap;
  padding: 5px 10px;
  z-index: -10;
  visibility: hidden;
  transition: 0.2s;
}
.productshowdelivery a span::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 10px solid;
  border-right: 10px solid transparent;
  border-bottom: 0;
  border-left: 10px solid transparent;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -8px;
  color: #69c0d4;
}
.productshowdelivery a:hover span {
  visibility: visible;
  z-index: 10;
}

.addcarticonouter {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
}
.showitemcount {
  width: 80px !important;
}
.addcarticonouter button {
  white-space: nowrap;
  margin: 0px;
}
.radiousbox001 span + span {
  position: relative;
  top: -1px;
  font-size: 18px !important;
}
.uptop {
  position: relative;
  top: -2px;
}

.mainboxproduct .usemobile {
  position: relative;
  display: flex;
}

.mainboxproduct .usemobile div {
  position: relative;
  box-sizing: border-box;
  display: flex;
  width: 100%;
}
.BoxInnercontent {
  flex-direction: column;
}
.mainboxproduct .usemobile img {
  display: block;
}
.Someinnerproduct li img {
  height: calc(100% - 18px);
}
.sidebarproductright .rightsidebarproduct img {
  height: initial;
}
.BoxInner a span {
  display: block;
  min-height: 20px;
  font-size: 12px;
  font-weight: 600;
  padding-top: 2px;
}
.Someinnerproduct {
  position: relative;
  height: 100%;
  margin: 0px;
}

.Someinnerproduct li {
  height: 99% !important;
}

.zaincash {
  background-image: url(https://is5-ssl.mzstatic.com/image/thumb/Purple112/v4/90/41/6b/90416b9b-b5e0-fbf0-b9da-831ec723287b/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/230x0w.webp);
  border: 0 !important;
  color: transparent !important;
  background-size: 87px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #050709 !important;
  width: 100%;
}
.cod {
  background-image: url(https://cdn1.iconfinder.com/data/icons/marketplace-and-shipping/64/COD_cash_on_delivery_shipping_payment_delivery-512.png);
  background-size: contain;
  border: 2px solid #000 !important;
  background-repeat: no-repeat;
  width: 100%;
  height: 37px;
  color: #000 !important;
  background-position: 10px;
  font-weight: bold;
}
.cod span {
  font-weight: 900;
}
.infinite-scroll-component {
  width: calc(100% - 8px);
  padding: 8px;
  overflow: hidden !important;
}

.infinite-scroll-component__outerdiv {
  width: calc(100% - 0px);
}
.sliderouterdealoftheday ul li img {
  width: 100%;
}
.bigsellerImg img {
  width: 100%;
}
.shareiconup {
  position: relative;
  z-index: 100;
  width: 0px;
}

.viewallitem {
  height: 100%;
  position: relative;
}

.viewallitem div {
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.viewallitem div button {
  display: inline-block;
  width: auto;
}
.viewallitem div button a button,
.remdomproduct.viewallitem button {
  background: #e1900f;
  border: 1px solid #e1900f;
  padding: 15px;
  width: 150px;
}

.viewallitem div button a button:hover,
.remdomproduct.viewallitem button:hover {
  background: transparent !important;
  color: #e1900f !important;
}
.viewallitem a {
  text-decoration: none !important;
}

/* 4/5/22 */
.filterslider span.MuiSlider-markLabel ~ span.MuiSlider-markLabel {
  margin-left: -35px;
  min-width: 94px;
  text-align: right;
}
.arabic .appledfilters svg {
  margin: 1px -6px 0px 5px;
}
.arabic .traHistory {
  text-align: right;
}
.mywishlistouter .MuiButton-fullWidth {
  width: calc(100% / 2 - 10px) !important;
  margin: 0px 5px;
}
.arabic .shareiconup div > div {
  left: 0px;
  right: inherit;
}
.arabic ul.MuiPagination-ul {
  flex-direction: row-reverse;
}

.arabic .reviewrating span span {
  right: 0px;
}
.arabic .shareiconup + div div + div > div + div {
  left: 0;
  right: inherit;
}
.addcarticonouter > div:first-child {
  position: absolute;
  bottom: -24px;
}
.addcarticonouter {
  position: relative;
}
.detailspagettab + div {
  position: relative;
}
.arabic .orderformcontrol {
  margin-left: 10px;
}
.shareiconup > svg:first-child {
  color: #fff;
  opacity: 1;
  padding: 7px 8px;
  background: #e1900f;
  border-radius: 5px;
  width: 44px;
  height: 44px;
  position: relative;
  left: -14px;
  top: 28px;
}
.arabic .avatararebic {
  position: relative;
  right: 40px;
}

.arabic .wishlistarebic {
  right: initial;
  left: 12%;
}
.arabic p.orderinvoice {
  display: flex;
  justify-content: space-between;
}
.topsidelogo {
  object-position: left;
  object-fit: contain;
}
@-moz-document url-prefix() {
  .arabic .MuiInputLabel-formControl {
    left: inherit;
    right: 11px;
    transform: translate(1px, 20px) scale(1);
  }
  .arabic .favwishlist {
    left: 7px;
    margin-top: -10px;
  }
  .arabic .reviewrating span span {
    right: initial;
  }
}

/* for cms pages */
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif !important;
  box-sizing: border-box;
  line-height: 1.5;
}
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1140px;
  margin: 0 auto;
  padding: 32px;
}

.wrapper .heading {
  text-transform: uppercase;
  color: #45b2cb;
}

.wrapper p {
  line-height: 1.5;
  font-size: 15px;
}

.wrapper strong {
  color: #6f6f6f;
}

.wrapper h5 {
  font-size: 16px;
}

.wrapper h3 {
  color: #45b2cb;
}

ul.benefits {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

ul.benefits li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
}

ul.benefits li img {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

div[lang="ar"] ul.benefits li img {
  width: 24px;
  height: 24px;
  margin-right: 0;
  margin-left: 16px;
}

ul.benefits li p {
  margin: 0;
}

.how-it-works {
  display: flex;
  flex-direction: column;
  padding: 32px 0;
}

.steps {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.step {
  width: 23%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  box-shadow: 0 0 4px #cccccc;
  border-radius: 16px;
}

.box {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 16px;
  margin-top: 16px;
  align-items: flex-start;
  padding: 16px;
  box-shadow: 0 0 4px #cccccc;
  border-radius: 8px;
}

.how-it-works .step img {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
}

.step h5 {
  text-align: center;
  line-height: 1.5;
  color: #6f6f6f;
  font-weight: 500;
}

.download-btn {
  width: 200px;
  height: 60px;
  object-fit: cover;
  object-position: top center;
  transition: ease 0.2s;
}

.download-app a:hover img {
  transform: scale(1.05);
  transition: ease 0.2s;
}

.banner-slide img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

form#form {
  /*max-width: 600px;*/
  width: 100%;
  margin: 32px 0;
  padding: 60px;
  box-shadow: 0 0 4px #ccc;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

form#form label {
  font-size: 14px;
  margin-bottom: 8px;
  color: #4e5050;
}

.form-control,
textarea {
  border: 1px solid #ccc;
  padding: 12px 16px;
  resize: none;
}

.btn {
  background: #45b2cb;
  border: 0;
  box-shadow: none;
  padding: 12px 16px;
  color: #fff;
  font-size: 16px;
}

/* Delivered By Sooqiraq Css */
.p-0 {
  padding: 0 !important;
}

.deliveredBy {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.deliveredBy li {
  display: flex;
  align-items: flex-start;
  width: 100%;
  border-bottom: 1px solid #efefef;
  padding: 16px;
}

.deliveredBy .icon-60 {
  width: 60px;
  height: 60px;
  margin-right: 16px;
}

div[lang="ar"] .deliveredBy .icon-60 {
  margin-right: 0;
  margin-left: 16px;
}
.itemfounfouter {
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 0px 15px #ccc;
  margin: 30px 0px;
  text-align: center;
}
a.customelink.btn {
  text-decoration: none;
  margin: 20px 0px;
  display: inline-block;
  background: #e1900f;
  transition: 0.5s;
  cursor: pointer;
}

a.customelink.btn:hover {
  border-radius: 5px;
}
.itemfounfouter h1 {
  margin-bottom: 0px;
}

.itemfounfouter p {
  margin-top: 3px;
}
.userprofileouter {
  margin: 10px auto;
}
.radioboxproduct a.Deliveryttype {
  position: absolute;
  top: 0px;
  background: #e1900f;
  padding: 6px 5px 6px 9px;
  text-decoration: none;
  color: #fff;
}

.radioboxproduct a.Deliveryttype::after {
  content: "";
  border-right: 20px solid #e1900f00;
  border-top: 28px solid;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: -20px;
  color: #e1900f;
  bottom: 0px;
}
.arabic .radiousbox.radioboxproduct h5 {
  left: 5px;
  right: inherit;
}
.arabic .radioboxproduct a.Deliveryttype::after {
  left: -20px;
  transform: rotate(180deg);
  right: initial;
}
.jss2 {
  width: 100% !important;
}
.detailstab button {
  display: flex;
  white-space: nowrap;
  flex-direction: revert;
}
.infinite-scroll-component__outerdiv .rightsidebarproduct img {
  max-height: 300px;
  object-fit: contain;
  object-position: top;
}
.m-0 {
  margin: 0px !important;
}
.viewallitem div {
  width: 100%;
}
.webouter {
  max-width: 1920px;
  margin: 0 auto;
}
.App{
  position: relative;
  z-index: 10;
}
.arabic .radioboxproduct a.Deliveryttype {
  right: 0px
}
.arabic .productfilter svg {
  position: relative;
  left: 9px;
}
.op-en {
  margin-top: -27px;
}
@media (max-width: 1800px) {
  .BoxInner a span {
    padding-top: 0px;
    min-height: 17px;
    position: relative;
    top: 1px;
  }
}
@media (max-width: 1500px) {
  .BoxInner a span {
    min-height: initial;
    height: 13px;
    top: 0px;
  }
  .BoxInner a span {
    font-size: 10px;
  }
  .filterapplied {
      font-size: 13px !important;
      margin: 0px !important;
  }
}
@media(max-width:1400px){
  .infinite-scroll-component__outerdiv .rightsidebarproduct img{
    max-height: 240px;
  }
    .griditemlist {
      grid-template-columns: repeat(4, 1fr);
    }
    .filterapplied {
        font-size: 12px !important;
    }
}
@media (max-width: 991.98px) {
  .BoxInner a span {
    height: 18px;
  }
  .arabic .radioboxproduct a.Deliveryttype::after {
    left: -18px !important;
  }
}

@media(max-width:767.98px){
  header>div>div {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap !important;
    }
  
    .topbarouter .togglebutton {
      display: none;
    }
  
    .wrapper {
      padding: 0px;
    }
    .mobile-space {
      margin-left: 0px !important;
    }
}
@media screen and (max-width: 680px) {
  .step {
    width: 100%;
    margin-bottom: 25px;
    background: #fff;
    box-shadow: 0 0 4px #cccc;
    border-radius: 16px;
  }

  .step h5 {
    font-size: 1.5em;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 681px) and (max-width: 1024px) {
  .step {
    width: 50%;
    margin-bottom: 25px;
    background: #fff;
    box-shadow: 0 0 4px #cccc;
    border-radius: 16px;
  }

  .step h5 {
    font-size: 1.5em;
    margin-bottom: 0;
  }
}

@media (max-width:1380px) {
  .BoxInner a p{
    font-size: 13px !important;
  }
  .rightsidebarproduct h5 {
    font-size: 14px !important;
  }
}

